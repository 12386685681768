<template>
  <div class="col-sm-12 col-md-6 col-lg-4 min-width-340px">
    <router-link class="text-decoration-none" v-bind:to="chartRoute" draggable="false" >
      <div class="mb-6 card-shadow border-radius-xl py-5 v-card v-sheet bg-primary-500 bg-primary-500-hover">
        <div class="d-flex justify-content-between align-items-center px-4 no-gutters">
          <div class="text-white text-size-36">{{ title | upper }}</div>
          <div>
            <i class="mdi text-size-48 text-white" v-bind:class="icon"></i>
          </div>
        </div>

        <div class="row px-4">
          <div class="col col-12">
            <p class="mb-0 text-body text-right">
              <span class="text-white text-sm font-weight-bolder">Exported: </span>
              <span class="text-white font-weight-light ms-1">
                {{ lastExported | dateFormat }}
                <i class="mdi mdi-check-circle text-success"></i>
              </span>
            </p>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<style scoped>

.min-width-340px {
  min-width: 340px;
}

</style>

<script>
export default {
  name: 'ChartCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'mdi-chart-bar'
    },
    chartRoute: {
      type: String,
      default: '/administration/gestion-des-statistiques'
    },
    lastExported: {
      type: String,
      default: ''
    }
  },
  data: () => ({}),
  methods: {}
}
</script>
