<template>
  <div id="content" style="flex: 1 1 auto; overflow-y: auto; min-height: 0px;">
    <div id="loading" v-if="this.$store.state.statistics.loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header class="pb-3">
        <h1>{{ $t('message.statistics') }} </h1>
      </header>

      <div class="container-fluid">
        <div class="row">
            <ChartCard v-for="chart in charts"
              v-bind:title=chart.label
              v-bind:icon=chart.icon
              v-bind:chart-route=chart.route
              v-bind:last-exported=chart.date>
            </ChartCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultLayout from '../../../layouts/DefaultLayout.vue'
import ChartCard from '../../../components/Statistics/ChartCard.vue'

export default {
  name: 'StatisticsMain',
  components: {
    ChartCard
  },
  data: () => ({
    errors: [],
    charts: []
  }),
  computed: {
    loading: {
      get: function () {
        return this.$store.state.statistics.loading
      },
      set: function (value) {
        this.$store.commit('storageStore/setLoading', value)
      }
    }
  },
  created () {
    this.$emit('update:layout', DefaultLayout)
    this.$store.dispatch('statistics/getCharts', {})
  },
  methods: {
    mountCharts () {
      this.charts = this.$store.state.statistics.charts
      this.charts.sort((a, b) => a.label.localeCompare(b.label))
    }
  },
  watch: {
    loading () {
      if (!this.$store.state.statistics.loading) {
        this.mountCharts()
      }
    }
  }
}
</script>
